@import "../../common/global.scss";

#header-container {
  background-color: $color-white;
  padding: 0;
  //position: -webkit-sticky;
  //position: sticky;
  //top: 5px;
  z-index: 4;
  // border-radius: 2px;
  //margin-top: 55px;
  @media screen and (max-width: 768px) {
    flex-flow: column;
  }
  // &.margin-top-15 {
  //   margin-top: 15px;
  // }
  &.tabs {
    box-shadow: 0px 3px 5px $color-grey-1;
    .nav-item.nav-link:first-child {
      border-left: 0;
    }
  }
  .header {
    padding: 15px;
    .product-name {
      font-size: 20px;
      color: $color-grey-8;
      max-width: 750px;
    }
    // border-bottom: 5px solid $color-grey-5;
    // .logo-container {
    //   .brand-logo {
    //     max-width: 200px;
    //     margin-bottom: 10px;
    //     @media screen and (max-width: 768px) {
    //       margin-bottom: 10px;
    //     }
    //   }
    // }
    // .download-screen-btn {
    //   border-radius: 5px;
    //   border: 1px solid $color-grey-9;
    //   font-weight: 100;
    // }
  }
  .filters {
    height: auto;
    //background-color: $color-blue-6; // $color-grey-5
    position: relative;
    //box-shadow: 0px 1px 5px $color-grey-1;
    // border: 1px dotted $color-grey-1;
    .filter-end-section {
      float: right;
      margin-top: -45px;
      margin-right: 10px;
      z-index: 1;
      position: relative;
    }
    &:hover {
      .filter-display {
        .filter-custom-drop-layout {
          &.drop-layout-show {
            transform: scaleY(1);
            height: auto;
            transition: transform 0.2s ease-in-out;
          }
        }
      }
      // .selected-filters {
      //   transform: scaleY(1);
      //   height: auto;
      // }
    }
    .filter-head {
      // margin-left: 10px;
      // color: $color-blue-8;
      // font-family: $font-family-primary-medium;
      font-size: 14px;
      padding: 15px 25px 0 25px;
      .brands-count {
        font-size: 12px;
        color: $color-grey-7;
      }
    }
    .filter-selected-display {
      width: 90%;
      overflow: hidden;
    }
    .filter-display {
      .selected-filter-name {
        //margin-right: 10px;
        margin: 13px 0px 0px 10px;
        padding-right: 10px;
        border-right: 1px solid $color-grey-1;
        font-size: small;
        .name {
          font-weight: bold;
        }
        .value {
          max-width: 105px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .selected-filter-name:last-child {
        border-right: none;
      }
      .filter-icon {
        position: absolute;
        top: 20px;
        left: 10px;
        color: $color-grey-4;
        z-index: 1;
      }
      // padding-bottom: 15px;
      .filter-custom-drop-layout {
        font-size: 14px;
        position: relative;
        //width: 150px;
        width: calc(100% - 110px);
        background-color: unset;
        max-height: 120px;
        margin-bottom: 13px;
        overflow: auto;
        .scroll-target {
          position: absolute;
          float: right;
          bottom: 5px;
          right: 5px;
          z-index: 3;
          background: #fff;
          padding: 2px 5px;
          color: $color-grey-4;
          border-radius: 3px;
        }
        &.drop-layout-show {
          height: 0px;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.1s ease;
          background-color: $color-white;
          padding: 0;

          position: absolute;
          background-color: $color-white;
          width: 100%;
          box-shadow: 0px 1px 5px $color-grey-1;
          z-index: 4;
          .drop-layout-container {
            padding: 15px 25px;
          }
        }
        .filter-names-container {
          // margin: 5px 0; //15px 0;
          .filter-name {
            margin: 10px 0 0 0;
            border: none;
            //cursor: pointer;
            z-index: 3;
            // border-radius: 500px;
            width: 20%;
            margin-right: 0px;
            // display: flex;
            align-items: center;
            padding: 6px 6px;
            white-space: nowrap;
            border-radius: 0;
            //background-color: $color-white;
            border-radius: 2px;
            position: relative;
            //border-left: 1px solid $color-grey-2;
            .form-control {
              height: 38px;
              border-left: 0;
              border-right: 0;
              border-top: 0;
              border-radius: 0;
              box-shadow: none;
              font-size: 14px;
              color: #316CF4;
            }
            &.selected {
              // border-radius: 500px;
              // background-color: $color-grey-3;
              border: 1px solid $color-grey-4;
            }
            &.disabled {
              // opacity: 0.7;
              // color: $color-grey-7;
              background-color: $color-grey-2;
            }
            .name {
              margin-right: 10px;
              margin-bottom: 10px;
              margin-top: 10px;
            }
            .name-top {
              position: absolute;
              z-index: 1;
              top: -10px;
              left: 7px;
              display: initial;
              padding: 5px;
              padding-bottom: 0;
              background-color: transparent;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 140px;
            }
            .name-top-boolean {
              position: absolute;
              z-index: 1;
              top: 15px;
              left: 60px;
            }
            .name-top-range {
              position: absolute;
              z-index: 1;
              top: 35px;
              padding: 5px;
              padding-bottom: 0;
              background-color: $color-white;
              text-align: center;
              width: 95%;
            }
            .selected-count {
              font-size: 10px;
              color: $color-grey-4;
            }
          }
          .btn-submit {
            min-width: 166px;
          }
          .filter-range {
            padding-top: 6px;
            width: 10%;
          }
          /*.filter-advanced {
            min-width: 150px;
            width: 150px;
            z-index: 2;
            .name {
              width: 100%;
              padding-bottom: 4px;
              border-bottom: 1px solid $color-grey-1;
            }
          }*/
          .size-50,
          .image_competitor-label-filter-name,
          .tenant_priority-label-filter-name,
          .brand_gap-label-filter-name, 
          .private_label-label-filter-name, 
          .third_party-label-filter-name,
          .best_seller-label-filter-name {
            width: 10%;
          }
          .size-75 {
            width: 15%;
          }
          .size-25 {
            width: 5%;
          }

          .review_count-label-filter-name{
            width: 10.5%;
          }
          .size-100 {
            .name-top {
              max-width: 280px;
            }
          }
          .size-75 {
            .name-top {
              max-width: 210px;
            }
          }
        }
      }
    }
    .selected-filters {
      display: flex;
      // height: 0px;
      // transform: scaleY(0);
      // transform-origin: top;
      // transition: transform 0 ease;
      font-size: 14px;
      flex-flow: row wrap;
      border-top: 2px solid $color-grey-5;
      padding: 10px 25px 0 25px;
      background-color: $color-blue-1;

      // position: absolute;
      // background-color: $color-green-3;
      // width: 100%;
      // box-shadow: 0px 2px 5px $color-grey-1;
      // z-index: 1;
      .selection-circle {
        border-radius: 500px;
        border: 1px solid $color-grey-1;
        padding: 0 10px;
        margin: 0 10px 10px 0;
        cursor: pointer;
        font-size: 12px;
        .cross {
          margin-left: 10px;
        }
      }
    }
    // .filter-custom {
    //   font-size: 12px;
    //   position: relative;
    //   width: 150px;
    //   background-color: unset;
    //   margin-left: 10px;
    //   .filter-name {
    //     border: none;
    //     position: relative;
    //     z-index: 2;
    //     border-radius: 500px;
    //     width: 100%;
    //     background-color: $color-blue-6;
    //     border: 1px solid $color-blue-6;
    //   }
    //   input {
    //     height: 12px;
    //     display: none;
    //   }
    //   .list-group {
    //     max-height: 320px;
    //     overflow: auto;
    //     width: 100%;
    //     background-color: $color-grey-8;
    //     border-radius: 3px;
    //     color: $color-white;
    //     top: 25px;
    //     padding-top: 20px;
    //     li {
    //       &:hover {
    //         background-color: #2f638e;
    //       }
    //       &.active {
    //         background-color: #2f638e;
    //       }
    //     }
    //   }
    // }
    .filter-menu-btn {
      width: auto;
      border-radius: 500px;
      padding: 5px 10px;
      text-align: center;
      margin: 0 0 0 10px;
      min-height: 30px;
      &.outOfStock {
        border: 1px solid $color-red-1;
        background-color: $color-white;
        &.active {
          background-color: $color-red-1;
          color: $color-white;
          border: none;
        }
      }
      &.mapViolation {
        border: 1px solid $color-orange-1;
        background-color: $color-white;
        &.active {
          background-color: $color-orange-1;
          color: $color-white;
          border: none;
        }
      }
      &.activePromotions {
        border: 1px solid $color-green-2;
        background-color: $color-white;
        &.active {
          background-color: $color-green-2;
          color: $color-white;
          border: none;
        }
      }
    }

    .form-switch {
      display: flex;
      .form-check-input {
        width: 40px;
        height: 20px;
        margin-top: 9px;
      }
    }
  }
  .sub-filters {
    position: relative;
    background: none;
    .filter-icon-btn {
      border: none;
      position: absolute;
      top: 20px;
      left: 10px;
      width: 20px;
      height: 20px;
      z-index: 2;
      background: none;
      .filter-icon {
        top: 0;
        left: 0;
      }
    }
    .selected-filter-name {
      border-left: 1px solid $color-grey-2;
      padding: 6px 10px;
      min-height: 50px;
    }
  }
  .date-picker-container {
    min-height: 44px;
    // margin-top: 10px;
    .place-holder {
      font-size: 12px;
      // color: $color-grey-7;
      // &::after {
      //   content: url("/images/icons/arrow_down.svg");
      // }
    }
    .date-range {
      font-size: 10px;
      width: 100%;
      color: $color-blue-5;
      border: none;
      outline: none;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      padding: 15px 0 0 10px;
      background-color: transparent;
      border-radius: 2px;
      &:focus {
        border: 1px solid $color-grey-4;
        // & ~ .place-holder::after {
        //   content: url("/images/icons/arrow_up.svg");
        // }
      }
    }
  }
}
.breadcrum-container {
  font-size: 0.9em;
  min-height: 35px;
}
.saved-user-filters-icon {
  &::after {
    display: none !important;
  }
}