@import "../global.scss";

.pre-elements {
  text-decoration: none;
  color:#464646;
  font-weight: 700;
}

.bread-crumb {
  // margin-top: 55px;
  display: flex;
  span {
    margin: auto;
    .link {
      color: $color-white; //$color-grey-10;
      border-bottom: 1px dashed $color-grey-10;
      font-size: 14px;
      line-height: 19px;
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: $color-black; //$color-blue-8;
        font-size: 16px;
        border: none;
      }
    }
  }
}
