@import "../global.scss";

.search-container {
  min-width: 200px; //400px;
  background-color: $color-white;
  padding: 4px;
  // margin-right: 25px;
  font-size: 13px;
  .custom-input {
    border: none;
    &:focus {
      outline: none;
    }
  }
  .small-btn {
    min-width: auto;
    background-color: $color-white;
    white-space: nowrap;
    box-shadow: none;
  }
  //   .m-10 {
  //     margin-left: 10px;
  //   }
}
