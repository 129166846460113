$font-family-primary-regular: "Heebo-regular";
$font-family-primary-medium: "Heebo-medium";

$color-blue-1: #ecf5fd;
$color-blue-2: #7ed2d6; //#26c1c9;
$color-blue-3: #17a2b8;
$color-blue-4: #0a7b9a;
$color-blue-5: #0079c4;
$color-blue-6: #f0f8ff;
$color-blue-7: #2c405a;
$color-blue-8: #0093ee;
$color-blue-9: #26c1c9;
$color-blue-10: #33a9f1;
$color-blue-11: #8dabc4;
$color-blue-12: #b7d2e5;

$color-white: #ffffff;
$color-white-opacity-24: #ffffff3d;
$color-black: #000000;
$color-black-opacity-24: #0000003d;
$color-whitesmoke: whitesmoke;

$color-grey-1: #cccccc;
$color-grey-2: #e7e8e8;
$color-grey-3: #eeeeee;
$color-grey-4: #818181;
$color-grey-5: #f9f6f6;
$color-grey-6: #aac0d2;
$color-grey-7: #576780;
$color-grey-8: #3f536e;
$color-grey-9: #c5d9e8;
$color-grey-10: #707070;
$color-grey-11: #7e95a7;
$color-grey-12: #efefef;
$color-grey-13: #d8e5ee;
$color-grey-14: #f5f5f5;
$color-grey-15: #2c3847;
$color-grey-16: #e8eef1;
$color-grey-17: #aeaeae;

$color-green-1: #93d044;
$color-green-2: #40b630;
$color-green-3: #ebf8ea;
$color-green-4: #78cb6d;

$color-orange-1: #fd7b1f;

$color-red-1: #ff151f;
$color-red-2: #fee4d1;
$color-red-3: #88060c;

$color-yellow-1: #ffff00;
$color-yellow-2: #faca00;

$color-purple-1: #ab7df6;

$color-brown-orange-1: #e18862;

// font sizes
$font-size-12: 12px;

// map dots color
$color-green-map-1: #00ff00;
$color-green-map-2: #02d002;
$color-green-map-3: #96c300;
$color-green-map-4: #c7b430;
$color-green-map-5: #ffa500;
$color-orange-map-1: #f3892d;
$color-orange-map-2: #ff4b00;
$color-red-map-2: #ff4b00;
$color-red-map-1: #ff0000;
$color-grey-map-1: #777777;

$color-grey-1: #cccccc;
$color-grey-2: #e7e8e8;
$color-grey-3: #eeeeee;
$color-grey-4: #818181;
$color-grey-5: #f9f6f6;
$color-grey-6: #aac0d2;
$color-grey-7: #576780;
$color-grey-8: #3f536e;
$color-grey-9: #c5d9e8;
$color-grey-10: #707070;
$color-grey-11: #7e95a7;
$color-grey-12: #efefef;
$color-grey-13: #d8e5ee;
$color-grey-14: #f5f5f5;
$color-grey-15: #2c3847;
$color-grey-16: #e8eef1;
$color-grey-17: #aeaeae;

// box shadow color
$color-box-shadow-1: #0000002c;

// brand background color
$color-purina-logo-bg: #ed1c24;
