.notification-header {
    margin-top: 2px;
    margin-right: 8px;
    position: relative;
}

.bell-icon {
    margin-top: 9px;
}

.notification {
    position: relative;
    margin-left: 7px;
}

.notification-indicater {
    color: red;
    position: absolute;
    top: 2px;
    left: 10px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
}

.notification-header:hover {
    cursor: pointer;
}

.notification-content {
    position: absolute;
    top: 38px;
    left: -335px;
    background-color: white;
    z-index: 9999;
    width: 400px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    a {
        text-decoration: none;
    }
}

.notification-data:hover {
    cursor: pointer;
}

.notification-page-body {
    margin: 10px 30px;
    min-height: 85vh;
    background-color: white;
}

.notification-page-body {
    padding: 20px 30px;
}

.notification-content {
    .notification-heading {
        padding: 10px;
        border-bottom: 1px solid #cbcac5;
        text-align: left;
        font-size: 14px;
        font-weight: 700;
    }
    .notification-data {
        background-color: white;
        min-height: 20px;
        overflow: scroll;
        b {
            padding: 1px 4px;
        }
        div {
            color: black;
            font-weight: 600;
        }
    }
}

.notification-image {
    width: 90px;
    height: 60px;
    border-radius: 25%;
}

.notch {
    position: absolute;
    top: -10px;
    left: 85.5%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
}  

.image-col {
    border-right: 1px solid #e4e4e4;
}

.unread {
    background-color: #f7f6f1 !important;
}

.notification-data {
    text-align: center;
    margin: 0px !important;
}

.success {
    background-color: #d4edda;
}

.danger {
    background-color: #f8d7da;
}

.warning {
    background-color: #fff3cd;
}

.notfication-circle {
    width: 8px;
}

.notification-page {
    background-color: #f7f6f1;
    height: 100vh;
}

.notification-body {
    background-color: white;
}

.notifications-data {

}

.notification-type-button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.button-selected {
    background-color: #f8f9fa;
    border-radius: 3px;
    border: 3px solid #F7F2F2;
    box-shadow: 1px 1px 1px 1px #F7F2F2;
}

.notification-footer {
    padding: 10px;
    text-align: center;
    a {
        text-decoration: none;
    }
}

.notification-row:hover {
    td {
        background-color: #F9F8F2 !important;
    }
}

.notification-body-row:hover {
    background-color: #F9F8F2 !important;
}