.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
    button {
      background: #090909;
    }
  .auth-inner {
    width: 50%;
    margin: auto;
    background: #ffffff;
    //box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 0rem 15rem 0rem 15rem;
    border-radius: 15px;
    transition: all 0.3s;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    .form-control:focus {
      border-color: #167bff;
      box-shadow: none;
    }
    h3 {
      text-align: center;
      margin: 0;
      line-height: 1;
      padding-bottom: 20px;
    }

    .custom-control-label {
      font-weight: 400;
    }
  }
  .input-group-text {
    background: transparent;
    color: #c3c9ce;
  }
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }

  .forgot-password a, .recover-password a {
    color: #0071CE;;
  }
  .loginPage{
    background-color: #fff;
  }
  .bg-cover-container {
    padding: 1rem;
    height: 100vh;
  }
  .bg-cover {
    background: url("/images/login-treay.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 20px;
  }
  .login-logo {
    background: url("/images/login-logo.png");
    height: 30px;
    width: 181px;
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
  .login-footer {
    position: absolute;
    bottom: 1rem;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .mr-n3,
  .mx-n3 {
    margin-right: -.75rem !important;
  }
  .display-4 {
    font-size: 2rem;
  }
  .text-center {
    text-align: center !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: .75rem !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .d-flex {
    display: flex !important;
  }
  @media (min-width: 992px) {
    .pl-lg-6,
    .px-lg-6 {
      padding-left: 4.5rem !important;
    }
  }
}

.pad{
  padding-left: 35px;
  padding-right: 35px;
}
#signinErrorList {
  list-style-type: none;
  padding-left: 0;
}
.privacy-policy-modal {
  .modal-body {
    height: 85vh;
  }
}