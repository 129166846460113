#page-layout {
  // padding: 1vw;
  // margin-left: 11vw;
  .menu-show-hide-icon {
    position: fixed;
    left: 11.4vw;
    top: 4vw;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: left 0.5s;
    -webkit-transition: left 0.5s;
    z-index: 4;
    &.close {
      left: 3.4vw;
    }
    &:hover {
      box-shadow: #ccc 0px 0px 8px 1px;
      // transition: box-shadow 0.3s;
    }
  }
}
