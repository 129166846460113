//@import "../common/global.scss";

.table-custom-scrollbar {
    overflow: auto;
    height: 70vh;

    thead th {
        position: sticky;
        top: -1px;
        z-index: 1;
        background: #ffffff;
        border-bottom: 0;
    }

    :not(:first-child) {
        border-top: none;
    }
}

.mt-50 {
    margin-top: 50px;
}

.gap-card {
    border-radius: 8px !important;
}

.gap-card-header {
    font-weight: 800;
    font-size: 16px;
    color: #333333;
    padding-top: 30px;
    opacity: 0.8;
}

.gap-sub-card {
    background-color: #fcfdfd;
    border-radius: 6px;
    padding: 1rem 0.5rem 1rem 0.8rem !important;
}

.pricingCardSubCard {
    height:102px
}

.pricingpositionCardSubCard {
    height:105px;
    width:170px;
}

.gap-sub-card-title {
    color:#666666;
    opacity: 0.7;
    font-size: 11px;
    font-weight: 700;
    margin-left: 0px !important;
}

.modal-title-header {
    color:#666666;
    opacity: 0.7;
    font-size: 12px;
    font-weight: bold;
    margin-left: 0px !important;
}

.gap-sub-card-data {
    margin-left: 6px !important;
    color: #000000;
    opacity: 0.8;
    font-size: 16px;
    font-weight: 600;
    margin-top: 2px !important;
}

.link-data {
    color: black;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

.products-list-link-data {
    color: black;
    font-weight: 600;
    text-decoration: none;
}

.section-header {
    font-size: 15px;
    font-weight: 700;
    color: #333333;
}

.section-header-underline {
    width: 40px !important;
    height: 4px !important;
    background-color: #b8b8b8;
    border-radius: 10px;
    margin-top: 5px !important;
    margin-left: 10px;
}

.bottome-right-arrow {
    width: 10px;
    color: #adadad;
}

.pie-chart-card {
    height: 344px;
    border: 2px solid #g8g8g8 !important;
    border-radius: 10px !important;
}

.pie-chart-card-dashboard-pricing {
    height: 290px;
    border: 1px solid #f8f8f8 !important;
    border-radius: 10px !important;
    padding-top: 3px;
}

.priceposition-chart-card-dashboard-pricing {
    height: 333px;
    border: 1px solid #f8f8f8 !important;
    border-radius: 10px !important;
    padding-top: 30px;
    background-color: #ffffff;
}

.pie-chart-title {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #000000 !important;
    opacity: 0.5 !important;
}

.link-icon {
    color: #b6b5b5;
}

.kpi-text-decoration {
    border-bottom: 2px solid #2D2E2E;
}

.section-divider {
    margin-bottom: 0px;
}

.dashboard-link-img {
    background-image: url("/images/link.svg");
    height: 16px;
    width: 16px;
    margin-right: 7px;
    display: inline-block;
}