.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  .form-check {
    &>div {
      margin-top: 2px;
      height: 17px;
    }
    a, btn-link  {
      color: #0071CE;
    }
  }
  .auth-inner {
    margin: auto;
    background: #ffffff;
    //box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border-radius: 15px;
    transition: all 0.3s;
    .form-control:focus {
      border-color: #167bff;
      box-shadow: none;
    }
    h3 {
      text-align: center;
      margin: 0;
      line-height: 1;
      padding-bottom: 20px;
    }

    .custom-control-label {
      font-weight: 400;
    }
    .forgot-password,
    .forgot-password a {
      text-align: right;
      font-size: 13px;
      padding-top: 10px;
      color: #7f7d7d;
      margin: 0;
    }

    .forgot-password a {
      color: #0071CE;
    }
  }

    #error-list {
        padding-left: 2rem;
    }
}


.floating-input-container {
    position: relative;
    margin-top: 8px;
}

.floating-label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    pointer-events: none;
    color: #33333300;
}

.floating-input-container.focused .floating-label {
    top: 0;
    font-size: 13px;
    color: #333;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
}

.floating-input-error {
    // display: none;
    color: #ffffff00;
}

.floating-input-error.display {
    // display: block;
    color: red;
}

.floating-input {
    font-size: 16px !important;
    padding: 10px !important;
}

.floating-input.errored,
.floating-input.errored:focus {   
    border-color: red;
}

.loader2 {
    width: 48px;
    height: 48px;
    border: 6px solid #EDEFEE;
    border-bottom-color: #F46A35;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation2 1.2s linear infinite;
}

@keyframes rotation2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}