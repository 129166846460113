//@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  //font-family: "Poppins", sans-serif;
  //font-family: "Inter", sans-serif;
  //font-family: "Noto Sans", sans-serif;
  //font-family: "Open Sans", sans-serif;
  font-family: "Lato", sans-serif;
  font-style: normal;
}

html {
  font-size: 13px;
}

.disp-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap-row {
  flex-wrap: wrap;
}

.justify-bw {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-all-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  .no-padding-l-r {
    padding-left: 0;
    padding-right: 0;
  }

  .no-padding-l {
    padding-left: 0;
  }

  .no-padding-r {
    padding-right: 0;
  }
}

.border-top-grey-3 {
  border-top: 1px solid $color-grey-1;
}

.border-bottom-grey-3 {
  border-bottom: 1px solid $color-grey-1;
}

.border-top-light-grey {
  border-top: 1px solid $color-grey-2;
}

.border-bottom-light-grey {
  border-bottom: 1px solid $color-grey-2;
}

.border-right-light-grey {
  border-right: 1px solid $color-grey-2;
}

.border-left-light-grey {
  border-left: 1px solid $color-grey-2;
}

.pd-top-10 {
  padding-top: 10px;
}

.no-link-decor,
.no-link-decor:hover {
  text-decoration: none;
  color: inherit;
}

.blue-big-text {
  color: $color-blue-2;
  font-size: 17px;
}

.width-100 {
  width: 100%;
}

.box-shadow-grey-1 {
  box-shadow: 0px 1px 4px $color-grey-1;
  border: 1px solid $color-grey-3;

  &.hover {
    &:hover {
      box-shadow: 10px 10px 8px $color-grey-1;
      transition: all 0.1s ease-in-out;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.show-menu {
  transform: unset;
  -webkit-transform: unset;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.hide-menu {
  transform: translate(-8vw);
  -webkit-transform: translate(-8vw);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  z-index: 3;
  padding-right: 0 !important;
  position: fixed;
  height: 100%;
}

.hide-menu~.page-container-default {
  margin-left: 4vw;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: calc(100% - 4vw);
}

.hide-menu.overlay-menu {
  margin-left: 8vw;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: calc(100% - 8vw);
}

.hide-menu.overlay-menu~.page-container-default {
  margin-left: 4vw;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: calc(100% - 4vw);
}

.page-container-default {
  //margin-left: 12vw;
  padding: 15px 0 15px 0;
  // padding-top: 20px;
  width: calc(100%);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  min-height: 90vh;

  &.background-grey {
    background-color: #f7f6f1;
  }
}
.background-grey {
  background-color: #f7f6f1;
}
.custom-slim-scrolbar {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $color-grey-1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-1;
    // outline: 1px solid $color-grey-1;
  }
}

.overflow-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.2 !important;
}

.opacity-2 {
  opacity: 0.4 !important;
}

.opacity-3 {
  opacity: 0.6 !important;
}

.opacity-4 {
  opacity: 0.8 !important;
}

.opacity-5 {
  opacity: 1 !important;
}

.purina-logo-css {
  background-color: $color-purina-logo-bg;
}

.margin-top-55 {
  margin-top: 55px;
}

// max width
.mw-20px {
  max-width: 20px;
}

// .show-menu {
//   transform: unset;
//   -webkit-transform: unset;
//   transition: 1s;
//   -webkit-transition: 1s;
// }

.section-divider {
  color: #000;
  height: 2px;
  margin-top: 0;
  opacity: 0.2;
}

.custom-container {
  margin-left: 30px;
  margin-right: 30px;
}

.alert-container {
  margin-right: -230px;
  float: right;
}

.product-gaps-dash-table-custom-scrollbar {
  overflow: auto;
  height: 40vh;

  thead th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border-bottom: 0;
  }
}

.dropdown-menu-column {
  z-index: 4 !important;
}

.breadcrumb {
  font-weight: 500;
  color: #000000;

  .active {
    color: #888888;
  }
}

.refresh-date {
  color: #000000;

  strong {
    color: #888888;
  }
}

.filters {
  padding: 0px 30px;
  box-shadow: 0px 10px 10px -15px #111;
}

.apply-button {
  height: 30px;
  width: 70px;
  font-size: 12px !important;
  color: #000;
}

.clear-all-button {
  margin-right: 8px;
  margin-top: 12px;
  color: #000;
  font-weight: 780;
}

.filter-button-group {
  //margin-right: -30px !important;
  //margin-top: 12px !important;
  //float: right;
  //margin-top: -105px;
  //z-index: 1 !important;
  background: #fff;
  width: 100px;
  position: absolute !important;
  top: 0;
  right: 0;
  height: 100%;
  padding-top: 10px;
}

.filter-show-hide {
  position: absolute;
  top: -40px;
  right: 40px;
}

.left-border {
  border-left: 1px solid #000;
  border-color: rgba(0, 0, 0, .2);
}

.right-border {
  border-right: 1px solid #000;
  border-color: rgba(0, 0, 0, .2);
}

.css-1s2u09g-control,
.css-1pahdxg-control,
.css-s231ga-control,
.css-lypbc8-control {
  min-height: 35px !important;
  font-size: 11px;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 1px 3px !important;
}

.css-g1d714-ValueContainer {
  padding: 2px 3px !important;
}

.css-319lph-ValueContainer {
  padding: 2px 4px !important;
}

.css-1rhbuit-multiValue {
  z-index: 1;
}

.css-1ydk3de {
  padding: 2px !important;
  padding-left: 6px !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}

.custom-row {
  min-height: 69px;
  border: 1px solid #eeeeee;
  text-align: left;
}

.custom-row-data {
  border-right: 3px solid #eeeeee;
  margin-right: 0px !important;
}

.table-custom-scrollbar-product-gaps {
  height: 700px;
  overflow-y: scroll;
  overflow-x: scroll;

  thead th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border-bottom: 0;
    white-space: pre;
  }
}

.fix-first-column {
  tr:first-child {
    th:first-child {
      z-index: 2;
    }
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0px;
    z-index: 1;
    border-right: 1px solid #efefef;
  }

  td:first-child {
    background: #fff;
  }
}

/*.noscrollbar::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}*/

.unset-first-col {
  thead {
    th {
      &:first-child {
        min-width: 100px !important;
      }
    }
  }

  tbody {
    td {
      &:first-child {
        min-width: 100px !important;
      }
    }
  }
}

.retailscape-table {
  border: 0 !important;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 1.1rem;

  thead {
    th {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      background-color: #F7F7F7;
      white-space: nowrap;
      top: 0px;
      z-index: 1;
      position: sticky;

      &:first-child {
        border-left: 1px solid #ccc;
        border-right: 1px solid #efefef;
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: #F7F7F7;
        min-width: 200px;
      }

      &:last-child {
        border-right: 1px solid #ccc;
      }
    }
  }

  tbody {
    td {
      border-bottom: 1px solid #ccc;
      background-color: #fff;

      &:first-child {
        position: sticky;
        left: 0;
        border-left: 1px solid #ccc;
        border-right: 1px solid #efefef;
        min-width: 200px;

        a {
          color: #000;
        }
      }

      &:last-child {
        border-right: 1px solid #ccc;
      }
    }
    tr:nth-child(even) {
      td {
        background: #F7F7F7 !important;
      }
    }
    tr:nth-child(odd) {
      td {
        background: white !important;
      }
    }
  }

  tfoot {
    border-top: 0px solid currentColor !important;

    th {
      position: sticky;
      bottom: -1px;
      z-index: 1;
      border-top: 0;
      white-space: pre;
      min-width: 5.5rem;
      padding-bottom: 10px;
      background-color: #F7F7F7;
      border-bottom: 0;
    }
  }

  tr {
    //height: 50px;
    vertical-align: top;
    border-bottom: 1px solid #e0e0e0;

    .image-column {
      width: 120px;
    }

    th {
      font-weight: 600;

      a {
        padding: 0;
        color: #000;
        font-weight: 600;
        text-align: left;
      }
    }

    td {
      a {
        padding-right: 5px;
      }
    }
  }
}

.products-list {
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: scroll;
  font-size: 1.1rem;

  table {
    border-collapse: separate;
    border-spacing: 0;

    thead {
      th {
        border-top: 1px solid $color-grey-1;
        border-bottom: 1px solid $color-grey-1;
        background-color: #F7F7F7;
        color: #000;
        position: sticky;
        top: 0px;
        z-index: 1;
        white-space: pre;
        min-width: 5.5rem;
        white-space: nowrap;

        &:first-child {
          min-width: 19vw;
          position: sticky;
          left: 0;
          top: 0;
          z-index: 3;
          background-color: #F7F7F7;
          border-left: 1px solid $color-grey-1;
          border-right: 1px solid #efefef;
        }

        a {
          color: #000;
          padding: 0;
          font-weight: 600;
          text-align: left;
        }

        &:last-child {
          border-right: 1px solid #ccc;
        }
      }
    }

    tbody {
      border-top: 1px solid $color-grey-1 !important;

      tr {
        border-top-width: 0px !important;

        &:hover {
          background: linear-gradient(269.91deg, rgba(0, 105, 191, 0.05) 75.06%, rgba(0, 105, 191, 0) 90.28%);
        }

        td {
          padding: 0;

          &:first-child {
            min-width: 19vw;
            position: sticky;
            left: 0;
            z-index: 2;
            background-color: #fff;
            border-left: 1px solid $color-grey-1;
            border-right: 1px solid #efefef;
          }

          &:last-child {
            border-right: 1px solid #ccc;
          }

          .custom-row-data {
            padding: 0;
            padding-left: 0.5rem;
            border-right: 0px;
          }
        }
      }
    }
  }

  tfoot {
    border-top: 0px solid currentColor !important;

    th {
      position: sticky;
      bottom: -1px;
      z-index: 1;
      border-top: 0;
      white-space: pre;
      min-width: 5.5rem;
      padding-bottom: 10px;
      background-color: #F7F7F7;
      border-bottom: 0;
    }
  }

  .my-product-gaps-images {
    display: flex;
    justify-content: center;
    height: 45px;
    width: 45px;
    margin: 5px 5px;

    img {
      max-height: 45px;
      max-width: 45px;
    }
  }

  .selected-row {
    padding: 0px;
    margin: 0px;
    border-right: 4px solid #0069bf;
    background: linear-gradient(269.91deg, rgba(0, 105, 191, 0.05) 0.06%, rgba(0, 105, 191, 0) 90.28%);
  }
}

.product-details {
  div {
    text-align: left;
    margin-left: 2px;
  }

  div:nth-child(1) {
    color: #333333;
    font-weight: 500;
    opacity: 0.4;
  }

  div:nth-child(2) {
    color: #333333;
  }

  div:nth-child(3) {
    color: #333333;
    font-weight: 500;
    opacity: 0.4;
  }
}

.cluster-main-img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
  &.small {
    max-width: 75px;
    max-height: 75px;
  }
}

.product-view {
  border: 1px solid #eeeeee;
  padding: 20px;

  .subtitle {
    color: #888888;
    opacity: 0.8;

    span {
      font-weight: 400;
    }
  }

  .title {
    color: #333333;
    font-size: 20px;
    padding-right: 0px;
  }

  .info-buttons {
    button {
      padding: 0px;
      padding-top: 4px;
    }
  }

  .price {
    color: #666666;
    font-weight: 600;
    border-right: 2px solid #b3b3b3;

    span {
      color: #000000;
    }
  }
}

.dropdown-menu {
  max-height: 200px;
  z-index: 1100 !important;
  overflow-y: scroll;
  
  .dropdown-item.active {
    background-color: rgb(0, 0, 0) !important
  }
}

.product-view-header {
  border: 0px;
  position: relative;

  .cluster-select-column-dropdown {
    position: absolute;
    right: 11px;
    top: 11px;
    float: right;
    width: auto;
  }

  div {
    margin-bottom: 2px solid red;
  }
}

.divider {
  border-bottom: 3px solid #f3f3f3;
  margin: 0px 30px;
}

.product-view-table {
  border-top: 0px;
}

.product-gap-data-row {
  border-radius: 100px;
  //opacity: 0.66;
  align-items: left;

  td {
    padding: 20px;
    padding-left: 5px;
  }

  .my-product-gaps-images {
    display: flex;
    justify-content: center;
    height: 45px;
    width: 45px;

    img {
      max-height: 45px;
      max-width: 45px;
    }
  }
}

.hilighted-row {
  background: #E0FFFF !important;
}

#subfilter-container {
  border-radius: 2px;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-flow: column;
  }
}

#product-image {
  align-items: center;
}

.pagination {
  font-weight: 600;

  .border-less {
    .page-link {
      border: 0;
      color: #000;
      background-color: transparent;
    }
  }

  .active {
    .page-link {
      background-color: #000 !important;
      color: #fff !important;
      border-radius: 5px;
      min-width: 35px;
      text-align: center;
    }
  }

  .next,
  .prev {
    .page-link {
      color: #000;
      background-color: transparent;
      border-radius: 2px;
      border-color: transparent;
    }
  }

  .disabled {
    .page-link {
      color: #c3c7cb !important;
      background-color: transparent !important;
      border: 0;
    }
  }

  .form-select {
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }
}

.product-table-first-column-container {
  display: flex;
}

#product-table-no-wrap {
  white-space: nowrap;
}

.filter-name {
  position: relative;
  z-index: 3;

  .name-top {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: 15px;
    background-color: #f7f7f7;
    padding: 5px;
    padding-top: 0;
    font-size: 12px;
  }
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.modal-backdrop {
  z-index: 1290 !important;
}

.modal {
  z-index: 1300 !important;
}

.suppress-modal-table {
  max-height: 300px;
  overflow-y: scroll;

  thead th {
    background-color: #F7F7F7;
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.add-ellipsis {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bs-tooltip-end .tooltip-arrow {
  top: 50% !important;
  margin-top: -5px;
}

.SSOLogin {
  background-color: #0071CE !important;
  border-color: #0071CE !important;
  margin-left: 0px !important;
  width: 100% !important;
}

.order-status-item:not(:last-child)::after {
  width: 108px !important;
}

.tooltip,
.popover {
  z-index: 1313 !important;
}

.full-width {
  .modal-dialog {
    min-width: 100%;
  }
}

.blurContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(3.6px);
  -webkit-backdrop-filter: blur(5.1px);
  z-index: 1111;
}

.expandView {
  position: relative;
  min-height: 20px;

  .bordered {
    border-left: 1px dotted #000;
    border-bottom: 1px dotted #000;
  }

  .expandViewBtnOpen {
    position: absolute;
    top: 3.5rem;
    left: 1rem;
    cursor: pointer;
  }

  .expandViewBtnClose {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    cursor: pointer;
  }
}

.tooltip-inner {
  max-width: 400px !important;
  text-align: left !important;
}