@import "../global.scss";

.main-header-container {
  background-color: $color-blue-5;
  position: fixed;
  width: 100%;
  z-index: 4;
  top: 0;
  height: 55px;
  .logo-container {
    text-align: center;
    width: 12vw;
    // background-color: $color-whitesmoke;
    border-right: 1px solid $color-grey-9;
    margin: auto;
    .brand-logo {
      max-width: 100%;
      // margin-bottom: 10px;
      max-height: 55px;
      // padding: 4px;
    }
  }
  .admin {
    // padding: 17px 30px;
    color: $color-white;
    text-transform: uppercase;
    font-size: 14px;
    border-right: 1px solid $color-white-opacity-24;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11vw;
  }
  .logout {
    color: $color-white;
    cursor: pointer;
  }
  .bungee-logo {
    max-width: 68px;
    margin-left: 20px;
  }
}
