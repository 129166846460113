@import "../global.scss";
.navbar-brand-img {
    margin-top: -14.5px;
    margin-bottom: -1rem;
    margin-right: 20px !important;
    margin-left: 10px !important;
}
.navbar-tenant-img {
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-left: -11px;
    margin-right: 0px;
}
.bungee-nav {
    height: 48px;
    .dropdown-item.active, .dropdown-item:active {
        background-color: #0069BF;
    }
}
.bungee-gaps-nav {
    .dropdown-item {
        font-weight: bold;
        border-bottom: 2px solid #eeeeee;
        padding: 0 10px;
    }
    .dropdown-item:focus, .active {
        background-color: transparent !important;
        color: #0069BF !important;
        border-bottom: 2px solid #0069BF;
    }
}
.menu-items {
    margin: 1px 10px;
    margin-bottom: -2px;
    &.active {
        border-bottom: 2px solid #000;
        .dropdown-toggle {
            color: #000 !important;
        }
    }
}

.bungee-nav .menu-items svg {
    width: 20px !important;
}

.main-nav {
    .nav-link {
        font-size: 1.2rem;
        padding: 0.85rem;
    }
    &>.nav-link {
        margin-left: 10px !important;
        margin-right: 10px !important;
        &.active {
            border-bottom: 2px solid #000;
            margin-bottom: -3px;
        } 
    }
    .dropdown-menu {
        overflow: visible;
    }
    .dropdown-item {
        position: relative;
        .dropdown-submenu {
            display: hidden;
            position: absolute;
            left: 100%;
            top: -7px;
        }
    }
    .dropdown-item:hover > .dropdown-submenu {
        display: block;
    }
}
.right-nav {
    .nav-link {
        font-size: 1.1rem;
        margin: 0 0.2rem;
    }
}
.icon-menu {
    .dropdown-toggle{
        &::after {
            display: none;
        }
    }
    .dropdown-menu {
        margin-left: -95px;
    }
}
